import React, { useState, useEffect } from "react";



const DevelopmentPage = () => {



    useEffect(() => {
        const timer = setTimeout(() => {


        }, 3000);

      }, []);



  return (
    <div className='light-background row container maxWidth-1920 mx-auto p-0 m-0'>
      <div className=''>
        <div className='header-height m-5'></div>
        <div className='col-md-12 col-xs-12'>
            <div className='row mb-5'>
            <div className='col-md-12 col-xs-12 mb-5'>      
                <p className='fw-100 text-dark fs-32'>
                    Our Mission Is To Provide Artworks That Help Deliver Messages, Make An Empact And Call To Action.
                </p>
            </div>

            <div className='col-md-12 col-xs-12 my-3'>
                <h3 className='fs-24 fw-600'>
                    Our things
                </h3>
                <p className='fw-300 text-dark fs-22'>
                    We make a wide range of artworks, such as Movies, Brand Videos, Explainer Videos, Product Videos, Pitch Videos, Branding, Demonstrations, User Experience Research, Web Development, Apps, and Virtual and Augmented Reality.
                </p>
            </div>



            </div>
        </div>
    </div>
</div>

     
  );
};

export default DevelopmentPage;
