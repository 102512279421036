import React from 'react';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';


import reviewerImg from '../../assets/imgs/ennocure/reviewer.png'; 

import geox01 from '../../assets/imgs/geox/geoxITC_01.jpg';
import geox02 from '../../assets/imgs/geox/geoxITC_02.jpg';
import geox03 from '../../assets/imgs/geox/geoxITC_03.jpg';
import geox04 from '../../assets/imgs/geox/geox_booth02.png';
import geox05 from '../../assets/imgs/geox/geox_booth03.png';
import geox06 from '../../assets/imgs/geox/geox_booth04.png';
import geox07 from '../../assets/imgs/geox/geoxITC_04.png';


import VideoLoader from '../../functions/VideoURLLoader';




const redirectToArrows = () => {
  window.location.href = '/works/arrows';
};

const redirectToVayyar = () => {
  window.location.href = '/works/vayyar';
};




const GeoxITCPage = () => {

  const videoList = [
    { folderName: 'geox', fileName: 'geox_demo01.mp4' },
    { folderName: 'geox', fileName: 'geox-hero.mp4' },
];


  return (
    <div className='row p-0 m-0'>
    <Header/>
    <div className='header-height'></div>

    <div className='p-md-5'>
        <div className='row p-0 m-0 '>
          <div className='col-sm-1 col-xs-12'></div>
          <div className='col-sm-11 col-xs-12'>
          <div className='row'>
              <div className='col d-flex'>
                  <h4 className='fw-500 fs-28 capitalize'>GeoX ITC</h4>
              </div>
                <div className='col d-flex float-end justify-content-end'>
                  <p className='fs-18 fw-300'>2024</p>
                </div>
             </div>
                <div className='col-12'>
                  <h4 className=' fw-400 capitalize'>
                      
                  </h4>
              </div>   
          
              <div className='row mt-4'>
              <div className='col-12'>

              </div>
            </div>  
          </div>
        </div> 





      <div className='row m-0'>
        <div className='col-sm-1 col-xs-12 '>
          </div>
              <div className='col-sm-11 col-xs-12'>

                <div className='col-12'>
                    <p className='fs-20 fw-200'>
                    GeoX experts use machine vision and deep learning technology to automate fast and accurate extraction of 3D objects from aerial imagery and create worldwide property databases.
                    This property data is for Insurance companies to study property features, get current property intelligence, effectively process claims, streamline underwriting, and evaluate risks resulting in significant cost reductions and lost revenue capturing.
                    </p>
                </div>
 

                <div className='col-12 my-5'>
                <VideoLoader video={videoList[1]} videoControl="" videoAutoplay="autoPlay" videoMuted="muted" videoLoop="loop" videoWidth="100%" />

                </div>


                



                <div className='col-12 my-4'>
                  <div className='row my-4 py-4'>
                  <div className='col'>

                  </div>
                  <div className='col-5'>
                    <p className='fs-20 fw-200'>
                        Fezzik were asked to create a short video sequence together with the booth design for Geox's ITC Las Vegas 2024 presentation. The video was created to drive more people to stop by the booth and get more information about the product and the special offer.                      </p>
                    </div>
                  </div>
                </div>

                

                <div className='col-12 py-4'>
                  <div className='row my-4'>
                    <div className='col-6 m-0 p-2'>
                      <img
                          src={geox01}
                          width='100%'
                        />
                    </div>
                    <div className='col-6 m-0 p-2'>
                      <img
                          src={geox02}
                          width='100%'
                        />
                    </div>
                    <div className='col-6 m-0 p-2'>
                      <img
                          src={geox03}
                          width='100%'
                        />
                    </div>
                    <div className='col-6 m-0 p-2'>
                      <img
                          src={geox07}
                          width='100%'
                        />
                    </div>
                  </div>



                </div>
 

                <div className='col-12 my-5'>
                <VideoLoader video={videoList[0]} videoControl="" videoAutoplay="autoPlay" videoMuted="muted" videoLoop="loop" videoWidth="100%" />
               
                </div>


                <div className='col-12 my-4'>
                  <div className='row my-4 py-4'>
                  <div className='col-7'>
                    <p className='fs-20 fw-200'>
                    The booth was designed using the brand design guidelines we developed in the earlier stage of the company and led by a dominant yellow spectrum. The building styles in the video and on print are designed from scratch using Maya 3D and substance shaders to create a realistic look. The video helped the marketing team on the ITC Las Vagas stage to collect interested people easily because they already stopped by the video to check what Geox has to provide to save them money.
                                          </p>
                    </div>
                      <div className='col'>
                      </div>
                  </div>
                </div>



                <div className='col-12 py-4'>
                  <div className='row my-4'>
                  <div className='col-12 m-0 p-2'>
                      <img
                          src={geox06}
                          width='100%'
                        />
                    </div>

                    <div className='col-6 m-0 p-2'>
                      <img
                          src={geox04}
                          width='100%'
                        />
                    </div>
                    <div className='col-6 m-0 p-2'>
                      <img
                          src={geox05}
                          width='100%'
                        />
                    </div>

                  </div>
                </div>




                    <div className='col-12 mt-5'>
                      <p className='fs-18 fw-400'>This work includes</p>
                        <div className='row'>
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Capabilities</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Animation</li>
                                <li className='py-1'>3D Modeling</li>
                                <li className='py-1'>3D Design</li>
                                <li className='py-1'>Booth Design</li>
                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Team</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Project Manager</li>
                                <li className='py-1'>Marketing Leader</li>
                                <li className='py-1'>Animator</li>
                                <li className='py-1'>Designer</li>

                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Cooperation</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Project</li>
                              </ul>
                          </div>

                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Industry</h4>
                              <li className='py-1'>Technology Services</li>
                                <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                                  <li className='py-1'>1.5 Months</li>
                              </div>
                            </div>

                          <div className='py-3'></div>
                      </div>


                      <div className='row'>
                  <div className='col d-flex'>
                      <p onClick={redirectToVayyar} className='fw-600 pointer'>Previous</p>
                  </div>
                  <div className='col d-flex text-right justify-content-end '>
                      <p onClick={redirectToArrows} className='fw-600 pointer'>Next</p>
                </div>
            </div>  


                </div>






          </div>



          {/* <div className='row px-sm-5 pb-5 m-0'>
              <div className='col-12 px-5 pb-5'>
                  <p className='fs-22 fw-200'>
                  Working with Fezzik on our animated explainer video was a delight from start to finish. Their team's professionalism and creativity shone through, resulting in a final product that exceeded our expectations. We're thrilled with the outcome and highly recommend their services.
                  </p>

                  <div className='d-flex pt-4'>
                    <img
                    src={reviewerImg}
                    width='60px'
                    height='60px'
                    />
                    <p className='fs-18 fw-300 ms-3'>Zuki Lavie<br></br>
                      <span className='fw-500 fs-16'>Co-Founder & CEO</span></p>
                  </div>
              </div>
          </div> */}





            <div className='row'>
                <Footer/>
            </div>
        </div>        
    </div>

  );
};


export default GeoxITCPage;


