import React, { useState, useEffect, useRef } from 'react';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';

import vayyarWall from '../../assets/imgs/vayyar/Mockup_Device_Wall.png'; 
import vayyarScreens from '../../assets/imgs/vayyar/app-mockup-1.png'; 
import vayyarSet from '../../assets/imgs/vayyar/all-set-vayyar-app.png'; 
import vayyar01 from '../../assets/imgs/vayyar/Vayyar01.png'; 


import arrowIcn from '../../assets/icns/arrow.svg'; 
import reviewerImg from '../../assets/imgs/vayyar/reviewer.png'; 
import VideoLoader from '../../functions/VideoURLLoader';


const redirectToArrows = () => {
  window.location.href = '/works/arrows';
};

const redirectToNavina = () => {
  window.location.href = '/works/navina';
};




const VayyarPage = () => {

  const videoList = [
    { folderName: 'vayyar', fileName: 'mockup-guides-vayyar-01.mp4' },
  ];
  

  const videoListScroll = [
    { folderName: 'vayyar', fileName: 'vayyar-mockup-01.mp4' },
    { folderName: 'vayyar', fileName: 'vayyar-mockup-02.mp4' },
    { folderName: 'vayyar', fileName: 'vayyar-mockup-03.mp4' },
    { folderName: 'vayyar', fileName: 'vayyar-mockup-04.mp4' },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRefs = useRef([]);

  useEffect(() => {
      videoRefs.current.forEach(video => {
          if (video) {
              video.load();
          }
      });
  }, []);




  
  const prevSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? videoListScroll.length - 1 : prevIndex - 1));
  };

  const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex === videoListScroll.length - 1 ? 0 : prevIndex + 1));
  };




  return (
    <div className='row p-0 m-0'>
    <Header/>
    <div className='header-height'></div>

    <div className='p-md-5'>
        <div className='row p-0 m-0 '>
          <div className='col-sm-1 col-xs-12'></div>
          <div className='col-sm-11 col-xs-12'>
          <div className='row'>
              <div className='col d-flex'>
                  <h4 className='fw-500 fs-28 capitalize'>Vayyar Care</h4>
              </div>
                <div className='col d-flex float-end justify-content-end'>
                  <p className='fs-18 fw-300'>2022</p>
                </div>
             </div>
                <div className='col-12'>
                    <h4 className=' fw-400 capitalize'>
                      Installation guide for The caregiver assistant that never sleeps
                    </h4>
                <img className='pt-5 img-fluid' src={vayyar01} alt=""/>
            </div>     
          </div>
        </div> 



          <div className='row px-5 py-5 m-0'>

          <div className='col-sm-1 col-xs-12 '>
          </div>

              <div className='col-sm-10 col-xs-12 py-5'>
                <div className='col-12'>
                <p className='fs-22 fw-200'>
                  Vayyar Care overcomes the limitations of traditional fall alert devices, providing touchless, camera-free elderly monitoring and fully automatic fall detection, integrated with your nurse call system. No cameras. No wearables. No buttons.
                </p>
                  <p className='fs-22 fw-200'>
                  In this work, we built a series of short explainer loops and UX Design for the Vayyar Care application. The Vayyar Care is a small device connected to the app and emergency forces. It is placed on a wall and detects falling situations in elderly homes, saving their lives when they are alone and need emergency help.                  </p>
                </div>
 
                  <div className='py-3'></div>

                    <div className='col-12'>
                      <p className='fs-18 fw-400'>This work includes</p>
                        <div className='row'>
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Capabilities</h4>
                              <ul className='p-0'>
                                <li className='py-1'>UX Review</li>
                                <li className='py-1'>Design System</li>
                                <li className='py-1'>App Animation</li>
                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Team</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Product Manager</li>
                                <li className='py-1'>UI Designer</li>
                                <li className='py-1'>UX Designer</li>
                                <li className='py-1'>Animator</li>
                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Cooperation</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Project</li>
  
                              </ul>
                          </div>

                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Industry</h4>
                                <li className='py-1'>Healthcare</li>
                                <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                                <li className='py-1'>2 Months</li>

                          </div>

                        </div>

                        <div className='py-3'></div>
    
                        {/* <div className='col d-flex'>
                            <h4 className='fs-22 fw-600'>Reviews</h4>
                        </div> */}
                        <div className='my-3 '></div>

                      <div className='d-flex'>
                          <h4 className='fs-22 fw-700 dark-text'><a href='https://vayyar.com/care' target='blank'>Visit Website &nbsp;↗</a> </h4>
                        </div>

                        </div>


                        <div className='row pt-4'>
                        <div className='col-12 pt-4'>
                              <VideoLoader video={videoList[0]} videoControl="" videoAutoplay="autoPlay" videoMuted="muted" videoLoop="loop" videoWidth="100%" />
                          </div>
                        </div>



                        <div className='row pt-4'>
                          <div className='col-12 pt-4'>
                              <p className='fs-22 fw-200'>
                                The installation process of the Vayyar Care device is a bit complicated and involves some measurements and essential information the installer needs to consider to mount the device in the right place inside the home; for these detailed installation steps, we made animated loops that demonstrate what the user needs to do clearly.
                              </p>
                            </div>
                        </div>

                </div>
          </div>













          <div className='row px-sm-5 py-5 m-0'>
            {/* <div className='col-12 px-5 py-5'>
              <h4 className='fw-600 fs-22 pb-3'></h4>
                <p className='fs-22 fw-400'>
                Though we had a pretty challenging timeline—one month for the website design—our team worked in parallel with the development team to launch the Ozone API’s website on time. This was a fantastic collaborative effort and the client was thrilled with the end result.
                </p>
              </div> */}


              <div className='row m-0 px-5'>
            {/* <div className='col-12'>
              <video
                  className='video'
                  src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/navina_mockups0001.mp4"
                  autoPlay
                  muted
                  loop
                  style={{ maxWidth: '100%' }}
                />
            </div> */}
          </div>
          <div className='row m-0 px-5'>
            <div className='col-sm-6 col-xs-12 mt-3'>
                <img
                  src={vayyarSet}
                  width='100%'
                />
            </div>
            <div className='col-sm-6 col-xs-12 mt-3'>
                <img
                  src={vayyarScreens}
                  width='100%'
                />
            </div>
            <div className='col-sm-6 col-xs-12 mt-3'>
                <img
                  src={vayyarWall}
                  width='100%'
                />
            </div>
          </div>


          <div className='row mt-5 mx-0 px-5'>

              <div className='col-sm-10 col-xs-12 py-5'>
                <p className='fs-22 fw-200'>
                  The set of animations for the Vayyar Care app boasts a vibrant interface structured in a modular fashion, delivering a fresh and engaging user experience that matches the needs of elderly users. To highlight the guidelines of the installation process, we crafted a modular design approach, presenting content in concise blocks that mark the attention points. Throughout the app, a cohesive visual language underscores Vayyar Care's capabilities.
                </p>


          <div className='row py-4'>
            <div className='position-relative py-4'>
                <div className="slider">
                  <button className="slider-button prev" onClick={prevSlide}>
                      <img src={arrowIcn} className='rotate-180' width={'35px'} alt="Previous" />
                    </button>
                    <div className="slider-video">
                    <div className='col-12 my-5 video'>
                          <VideoLoader video={videoListScroll[currentIndex]} videoControl="" videoAutoplay="autoPlay" videoMuted="muted" videoLoop="loop" videoWidth="100%" />
                      </div>
                    </div>

                    <button className="slider-button next" onClick={nextSlide}>
                      <img src={arrowIcn} width={'35px'}  alt="Next" /> 
                    </button>
                </div>
              </div>
            </div>



                <p className='fs-22 fw-200'>
                  We made a set of different animations to go along the installation process of Vayyar Care. Each animation explains the specific point of the installation process and helps the users get a simple demonstration of the required actions the user needs to complete. Between the animations, you can measure the room, check for forbidden elements in the device field, and get a guide to calibrate and activate the sensor.
                </p>

              </div>
            </div>
        </div>




          <div className='row px-5 py-5 m-0'>
            <div className='col-12 px-5 py-5'>
              <h4 className='fw-600 fs-22 pb-3 opacity-50'>Outcome</h4>
              <h4 className='fw-400 fs-42 pb-3'>Minimalize massive data into simple-to-understand installation process</h4>
            </div>
          </div>



{/* 
          <div className='row px-sm-5 py-5 m-0'>
            <div className='col-12 px-5 py-5'>
              <h4 className='fw-600 fs-22 pb-3 opacity-50'>Results</h4>
              <h4 className='fs-42 fw-600'>Easy Explains Navina's Product</h4>


              <div className='ro border-bottom-dark-1px'>
                  <div className='col-sm-11 col-xs-12 container text-center capitalize'>
                      <div className='row px-5 py-5'>
                        <div className='col-md-4 col-sm-12 p-4'>
                            <h4 className=' fw-700'>60%</h4>
                              <p className='fs-24 fw-400 '>more engagments</p>
                          </div>
                          <div className='col-md-4 col-sm-12 p-4'>
                            <h4 className=' fw-700'>50%</h4>
                              <p className='fs-24 fw-400 '>increase in website time view</p>
                          </div>
                          <div className='col-md-4 col-sm-12 p-4'>
                            <h4 className=' fw-700'>55%</h4>
                              <p className='fs-24 fw-400 '>growth in leads</p>
                          </div>

                      </div>
                    </div>
                  </div>
              </div>
          </div> */}


          <div className='row px-sm-5 pb-5 m-0'>
              <div className='col-12 px-5 pb-5'>
                  <p className='fs-22 fw-200'>
                    The animations crafted for the Vayyar Care app were crystal clear, ensuring that the installation process is now seamless and effortless for users. We're proud to have simplified the user experience, making Vayyar Care more accessible and user-friendly than ever.
                  </p>

                  <div className='d-flex pt-4'>
                    <img
                    src={reviewerImg}
                    width='60px'
                    height='60px'
                    />
                    <p className='fs-18 fw-300 ms-3'>Hen Shidlansik<br></br>
                      <span className='fw-500 fs-16'>Product Manager Vayyar App</span></p>
                  </div>
              </div>
          </div>



          <div className='row px-sm-5 m-0'>
              <div className='col d-flex'>
                  <p onClick={redirectToNavina} className='fw-600 pointer'>Previous</p>
              </div>
              <div className='col d-flex text-right justify-content-end '>
                  <p onClick={redirectToArrows} className='fw-600 pointer'>Next</p>
              </div>
          </div>  


            <div className='row pb-5 px-sm-5 m-0'>
                <Footer/>
            </div>
        </div>


        
    </div>

    
  );
};


export default VayyarPage;


