import React, {useRef, useEffect } from 'react';

const Menu = ({ isDarkBackground, setIsMenuOpen }) => {
    const menuRef = useRef(null);

    useEffect(() => {
        menuRef.current.style.height = `${menuRef.current.scrollHeight}px`;
    }, []);

    const closeMenu = () => {
        document.body.style.overflow = 'auto'; // Enable scrolling
        menuRef.current.style.height = '0';
        setTimeout(() => {
            setIsMenuOpen(false);
        }, 500); // Adjust the timeout to match your transition duration
    };


  const redirectToProposal = () => {
    window.location.href = '/proposal';
  };

  const redirectToWorks = () => {
    window.location.href = '/works';
  };

  const redirectToAbout = () => {
    window.location.href = '/about';
  };

  const redirectToServices = () => {
    window.location.href = '/services';
  };

  const redirectToPartners = () => {
    window.location.href = '/partners';
  };
  

  


  return (
    <div className="menu position-absolute z-3 light-background p-0 m-0" ref={menuRef}>
        <div className='menu-content'>
            <div className='justify-content-end float-end m-3'>
                <div
                onClick={closeMenu}
                    className='header-menu '>
                <p className='dark-background rounded-5 fs-18 fw-700 pointer px-3 py-1 light-text'>Close</p>
            </div>
        </div>
           <div className="p-5">
              <h3 onClick={redirectToProposal} className='mb-3 fs-5em fw-500 pointer'>
                  Proposal
              </h3>
              <h3 onClick={redirectToServices} className='mb-3 fs-5em fw-500 pointer'>
                  Services
              </h3>
              <h3 onClick={redirectToWorks} className='mb-3 fs-5em fw-500 pointer'>
                  Works
              </h3>
              {/* <h3 onClick={redirectToPlayground} className='mb-3 fs-5em fw-500 pointer'>
                  Playground
              </h3> */}
              <h3 onClick={redirectToPartners} className='mb-3 fs-5em fw-500 pointer'>
                  Partners
              </h3>
              <h3 onClick={redirectToAbout} className='mb-3 fs-5em fw-500 pointer'>
                  Nice
              </h3>
            </div>
          </div>
        </div>
      );
    }
  
  export default Menu;
  