import React from 'react';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';

import reviewerImg from '../../assets/imgs/spark/reviewer.png'; 
import VideoLoader from '../../functions/VideoURLLoader';


const redirectToArrows = () => {
  window.location.href = '/works/arrows';
};

const redirectToVayyar = () => {
  window.location.href = '/works/vayyar';
};




const SparkPage = () => {


  const videoList = [
    { folderName: 'spark', fileName: 'Spark.mp4' },

  ];



  return (
    <div className='row p-0 m-0'>
    <Header/>
    <div className='header-height'></div>

    <div className='p-md-5'>
        <div className='row p-0 m-0 '>
          <div className='col-sm-1 col-xs-12'></div>
          <div className='col-sm-11 col-xs-12'>
          <div className='row'>
              <div className='col d-flex'>
                  <h4 className='fw-500 fs-28 capitalize'>Spark</h4>
              </div>
                <div className='col d-flex float-end justify-content-end'>
                  <p className='fs-18 fw-300'>2020</p>
                </div>
             </div>
                <div className='col-12'>
                    <h4 className=' fw-400 capitalize'>
                      3D aniamted Explainer  video for spark - success-based education program
                    </h4>
            </div>  

              <div className='row mt-4'>
                <div className='col-12 my-5 video'>
                    <VideoLoader video={videoList[0]} videoControl="controls" videoAutoplay="" videoMuted="" videoLoop="" videoWidth="100%"/>
                </div>
            </div>
          </div>
        </div> 



      <div className='row px-5 py-5 m-0'>
        <div className='col-sm-1 col-xs-12 '>
          </div>

              <div className='col-sm-10 col-xs-12 py-5'>
                <div className='col-11'>
                    <p className='fs-22 fw-200'>
                      Spark asked for a simple but fine-quality video to help them present their tuition aid solution to potential students and high institutes. Spark offers financial help for students who want to develop their skills and pay after getting a new job with a high salary while helping the institutes fill their classes with highly potential students.
                    </p>
                </div>
 
                  <div className='py-3'></div>

                    <div className='col-12'>
                      <p className='fs-18 fw-400'>This work includes</p>
                        <div className='row'>
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Capabilities</h4>
                              <ul className='p-0'>
                                <li className='py-1'>2D Animation</li>
                                <li className='py-1'>Illustration</li>
                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Team</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Project Manager</li>
                                <li className='py-1'>Designer</li>
                                <li className='py-1'>Animator</li>
                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Cooperation</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Project</li>
  
                              </ul>
                          </div>

                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Industry</h4>
                                <li className='py-1'>Education</li>
                                <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                                <li className='py-1'>1 Months</li>

                          </div>

                        </div>

                        <div className='py-3'></div>
    
                      </div>
                </div>
          </div>



          <div className='row px-sm-5 pb-5 m-0'>
              <div className='col-12 px-5 pb-5'>
                  <p className='fs-22 fw-200'>
                  Working with Fezzik on our animated explainer video was a delight from start to finish. Their team's professionalism and creativity shone through, resulting in a final product that exceeded our expectations. We're thrilled with the outcome and highly recommend their services.
                  </p>

                  <div className='d-flex pt-4'>
                    <img
                    src={reviewerImg}
                    width='60px'
                    height='60px'
                    />
                    <p className='fs-18 fw-300 ms-3'>Zuki Lavie<br></br>
                      <span className='fw-500 fs-16'>Co-Founder & CEO</span></p>
                  </div>
              </div>
          </div>



          <div className='row px-sm-5 m-0'>
              <div className='col d-flex'>
                  <p onClick={redirectToVayyar} className='fw-600 pointer'>Previous</p>
              </div>
              <div className='col d-flex text-right justify-content-end '>
                  <p onClick={redirectToArrows} className='fw-600 pointer'>Next</p>
            </div>
        </div>  
            <div className='row pb-5 px-sm-5 m-0'>
                <Footer/>
            </div>
        </div>        
    </div>

  );
};


export default SparkPage;


