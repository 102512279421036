import React, { useState, useEffect } from 'react';
import logoDark from '../header/logo-dark.svg';
import { Link } from 'react-router-dom';
import lottie from 'lottie-web';

import icn_vr from '../assets/icns/vr_icn.png'; 
import icn_apps from '../assets/icns/apps_icn.png'; 
import icn_movie from '../assets/icns/movie_icn.png'; 



import animationData from '../assets/icns/loading_hand.json';


const QuickProposalPage = () => {

const [form_steps, set_form_step] = useState(0);
const [loading, set_loading] = useState(false);
const [selected_item, set_selected_item] = useState(null);


const handle_next_step = () => {
    handleInputDetailsChange();
    set_form_step(form_steps + 1);
};

const handle_back_step = () => {
    set_form_step(form_steps - 1);
};

const handle_start_over = () => {
    set_form_step(0);
};

const new_date = get_current_date();
const [form_responses, set_form_responses] = useState({
    package: '',
    length: 60,
    style: '',
    deadline: '',
    budget: '',
    date: new_date,
    full_name: '',
    email: '', 
    notes: '',
  });

  function get_current_date() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    return `${day}-${month}-${year}`;
  }

  


    const handle_service_selection = (service) => {
        set_selected_item(service);
        handleInputDetailsChange(service, 'package');

    }

    const handle_style_selection = (style) => {
        set_selected_item(style);
        handleInputDetailsChange(style, 'style');

    }


    useEffect(() => {
        handleInputDetailsChange(form_responses.length, 'length');
    }, [form_responses.length]);


    const handleInputDetailsChange = (value, field) => {
        set_form_responses(prevState => ({
          ...prevState,
          [field]: value
        }));  

      };


      const handle_submit_data = async () => {
        handleInputDetailsChange();

        set_loading(true); 
    
        const animation = lottie.loadAnimation({
            container: document.getElementById('loadingAnimation'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationData 
        });
    
        setTimeout(() => {
            animation.stop(); // Stop the animation
            animation.destroy(); // Remove the animation from the DOM
            set_loading(false); // Set loading to false
            set_form_step(form_steps + 1); // Proceed to the next step
        }, 5000);

        console.log(form_responses)
  
        try {
          const response = await fetch('https://fse7h1rrs4.execute-api.eu-south-1.amazonaws.com/default/fezzik_proposal_messages', {
              method: 'POST',
              body: JSON.stringify({ 
                new_proposal: form_responses,
              })
            });
  
            const data = await response.json();
  
          } catch (error) {
            console.error('Error calling Lambda function:', error);
          }
        }



  return (
    <div className='gradient_bg h-100 '>


                        
      {form_steps > 0 && !loading && (
        <div className='row container'>
            <div className='proposal_btns col-12'>
            {form_steps < 5 && (
                <div className=''>
                    <div onClick={handle_back_step} className='d-inline-flex'><span className='fw-600 fs-18 pointer'>Back</span></div>
                    <div onClick={selected_item ? handle_next_step : null}  className={`d-inline-flex float-end ${selected_item ? '' : 'disabled_btn'}`}>
                        <span className='fw-600 fs-18 pointer'>Next</span>
                    </div>
                    </div>
                )}

                {form_steps === 5 && !loading && (
                <div className=''>
                    <div onClick={handle_back_step} className='d-inline-flex'><span className='fw-600 fs-18 pointer'>Back</span></div>
                    <div onClick={handle_submit_data} className='d-inline-flex float-end'><span className='fw-600 fs-18 pointer'>Next</span></div>
                </div>
                )}

            </div>
        </div>
        )}


        <div className='row p-0 m-0'>
            <div className='col'>
                <Link to="/works" className='logo-box'>
                    <p className='d-flex fs-24'>
                    <img className='logo m-0 pe-3' src={logoDark} alt="Logo"/>
                    <span className='py-3 dark-text'>Fezzik</span></p>
                </Link>
            </div>

            <div className='col d-inline-flex justify-content-end pt-3'>
                <a className='fs-24 text-dark fw-500' href="mailto:hello@fezzik.art">hello@fezzik.art&nbsp;↗</a>
            </div>
        </div>


        <div className='px-5'>
            <div className='container'>


                {loading &&(
                <div className='m-5' id="loadingAnimation">
                    <p className='fs-32 fw-500'>Loading...</p>
                </div>

            )}
                
                {form_steps === 0 && (
                    <div className='row mx-0 mt-5'>
                        <div className='col-xs-12 col-sm-7 col-lg-5 capitalize'>
                                <h4 className='fs-40 fw-w400'>
                                    Let's make an intial proposal for your video
                                    </h4>
                                    </div>
                                <p onClick={handle_next_step} className='mt-5'>
                                    <span className='fw-700 fs-18 pointer'>Start</span>
                                </p>
                        </div>
                        )}

                    {form_steps === 1 && (
                        <div className='row p-0 m-0'>
                            <h4 className='fs-28 mt-sm-5'>Service</h4>
                            <div className={`col-md-4 col-sm-12 pointer text-center ${selected_item === 'Animated Video' ? 'selected' : ''}`} onClick={() => handle_service_selection('Animated Video')}>
                                <img className='img-fluid' src={icn_movie} alt=""/>
                                <p className='fw-500 fs-18'>Animated or Live Video</p>
                            </div>
                            <div className={`col-md-4 col-sm-12 pointer text-center ${selected_item === 'Web Animation' ? 'selected' : ''}`} onClick={() => handle_service_selection('Web Animation')}>
                                <img className='img-fluid' src={icn_apps} alt=""/>
                                <p className='fw-500 fs-18'>Animation for websites & apps</p>
                            </div>
                            <div className={`col-md-4 col-sm-12 pointer text-center ${selected_item === 'Vr Exp' ? 'selected' : ''}`} onClick={() => handle_service_selection('Vr Exp')}>
                                <img className='img-fluid' src={icn_vr} alt=""/>
                                <p className='fw-500 fs-18'>VR Experience</p>
                            </div>
                        </div>
                    )}

                        {form_steps === 2 && selected_item && (
                            <div className='row p-0 m-0'>
                                <h4 className='fs-28 mt-sm-5'>Length</h4>
                                        <div className='col-12'>
                                            <input
                                                type="range"
                                                min={0}
                                                max={120}
                                                step={15} 
                                                value={form_responses.length}
                                                className="form-range"
                                                onChange={(e) => handleInputDetailsChange(e.target.value, 'length')}
                                            />
                                        <p>{form_responses.length} sec</p>
                                    </div>
                                </div>
                        )}

{/* 
                        {form_steps === 3 && (
                            <div className='col-12 pb-5 mb-3'>
                            <h4 className='fs-28 mt-sm-5'>Style</h4>
                                    <div className='row'>
                                    <div className={`col-md-4 col-sm-12 pointer text-center ${selected_item === '2D Simple' ? 'selected' : ''}`} onClick={() => handle_style_selection('2D Simple')}>
                                        <img className='img-fluid' src={two_d_simple} alt=""/>
                                            <p className='fw-500 fs-18'>2D Simple</p>
                                    </div>
                                    <div className={`col-md-4 col-sm-12 pointer text-center ${selected_item === '2D Complex' ? 'selected' : ''}`} onClick={() => handle_style_selection('2D Complex')}>
                                        <img className='img-fluid' src={two_d_complex} alt=""/>
                                            <p className='fw-500 fs-18'>2D Complex</p>
                                    </div>
                                    <div className={`col-md-4 col-sm-12 pointer text-center ${selected_item === '3D Simple' ? 'selected' : ''}`} onClick={() => handle_style_selection('3D Simple')}>
                                        <img className='img-fluid' src={three_d_simple} alt=""/>
                                            <p className='fw-500 fs-18'>3D Simple</p>
                                    </div>
                                    <div className={`col-md-4 col-sm-12 pointer text-center ${selected_item === '3D Complex' ? 'selected' : ''}`} onClick={() => handle_style_selection('3D Complex')}>
                                        <img className='img-fluid' src={three_d_complex} alt=""/>
                                            <p className='fw-500 fs-18'>3D Complex</p>
                                    </div>
                                    <div className={`col-md-4 col-sm-12 pointer text-center ${selected_item === 'Live Action' ? 'selected' : ''}`} onClick={() => handle_style_selection('Live Action')}>
                                        <img className='img-fluid' src={live_real} alt=""/>
                                            <p className='fw-500 fs-18'>Live Action</p>
                                    </div>
                                    <div className={`col-md-4 col-sm-12 pointer text-center ${selected_item === 'Other' ? 'selected' : ''}`} onClick={() => handle_style_selection('Other')}>
                                        <img className='img-fluid' src={other_style} alt=""/>
                                            <p className='fw-500 fs-18'>Other</p>
                                    </div>
                                </div>
                            </div>
                        )} */}


                        {form_steps === 4 && (
                            <div className='col-12'>
                            <h4 className='fs-28 mt-sm-5'>Deadline</h4>
                                    <div className='row'>
                                        <div className='col border-bottom-dark-1px'>
                                        <input
                                            className='form-control'
                                            type="date" 
                                            value={form_responses.deadline}
                                            onChange={(e) => handleInputDetailsChange(e.target.value, 'deadline')}
                                            style={{ color: '#000' }}
                                        />
                                        </div>
                                    </div>
                                </div>

                        )}


                        {form_steps === 5 && !loading && (
                            <div className='col-12'>
                            <h4 className='fs-28 mt-sm-5'>Budget</h4>
                                    <div className='row'>
                                        <div className='col border-bottom-dark-1px'>
                                        <input
                                            className='form-control'
                                            type="text"
                                            placeholder='Enter amount'
                                            onChange={(e) => handleInputDetailsChange(e.target.value, 'budget')}
                                            />
                                    </div>
                                </div>
                            </div>
                        )}

                        {form_steps === 6 && !loading &&  (

                            <div className='col-12 mt-5 px-5'>


                                 
                                        
                                <div className='row mx-xs-5'>
                                <p className='fs-32 fw-600'>
                                    We have an excellent offer for you, but we need more details.
                                    <br></br>
                                    Please fill out your details below, and we'll get back to you before the end of the next day                                    
                                    </p>
                                        {/* <h4 className='fs-22'>Details</h4> */}

                                    <div className='col-12 border-bottom-dark-1px my-3'>
                                        <input
                                            className='form-control '
                                            type="text"
                                            placeholder='full name'
                                            onChange={(e) => handleInputDetailsChange(e.target.value, 'full name')}
                                            />
                                    </div>
                                    <div className='col-12 border-bottom-dark-1px my-3'>
                                        <input
                                            className='form-control'
                                            type="text"
                                            placeholder='email'
                                            onChange={(e) => handleInputDetailsChange(e.target.value, 'email')}
                                            />
                                    </div>
                                    <div className='col-12 border-bottom-dark-1px my-3'>
                                        <textarea
                                            className='form-control'
                                            type="text"
                                            placeholder='message'
                                            onChange={(e) => handleInputDetailsChange(e.target.value, 'notes')}
                                            />
                                    </div>
                                </div>
                                <div onClick={handle_submit_data} className='my-3'><span className='fw-600 fs-18 pointer'>Submit</span></div>

                            </div>
                            )}


                            {form_steps === 7 && !loading &&(
                                <div className='col-12 mt-5 px-5'>
                                    <div className='row mx-xs-5'>
                                        <p className='fs-32 fw-600'>
                                            Thank You, See U Soon
                                        </p>
                                    </div>
                                </div>    
                            )}

                        </div>
                    </div>   
            </div>

  );
};

export default QuickProposalPage;
