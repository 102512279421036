import React from 'react';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';

import ennocure_app_one from '../../assets/imgs/ennocure/app_mockup_01.png';
import ennocure_pad_demo_image from '../../assets/imgs/ennocure/pad_demo_image.png'; 
import ennocure_pad_on_arm from '../../assets/imgs/ennocure/pad_on_arm.png'; 
import ennocure_main_image from '../../assets/imgs/ennocure/pad_on_arm.png'; 

import reviewerImg from '../../assets/imgs/ennocure/reviewer.png'; 





const redirectToArrows = () => {
  window.location.href = '/works/arrows';
};

const redirectToVayyar = () => {
  window.location.href = '/works/vayyar';
};




const EnnocurePage = () => {
  return (
    <div className='row p-0 m-0'>
    <Header/>
    <div className='header-height'></div>

    <div className='p-md-5'>
        <div className='row p-0 m-0 '>
          <div className='col-sm-1 col-xs-12'></div>
          <div className='col-sm-11 col-xs-12'>
          <div className='row'>
              <div className='col d-flex'>
                  <h4 className='fw-500 fs-28 capitalize'>Ennocure</h4>
              </div>
                <div className='col d-flex float-end justify-content-end'>
                  <p className='fs-18 fw-300'>2024</p>
                </div>
             </div>
                <div className='col-12'>
                    <h4 className=' fw-600 capitalize'>
                    A series of web animations to demonstrate the Ennocure wound therapy kit
                    </h4>
                <img className='pt-5 img-fluid' src={ennocure_app_one} alt=""/>
            </div>     
          </div>
        </div> 



          <div className='row px-5 py-5 m-0'>

          <div className='col-sm-1 col-xs-12 '>
          </div>

              <div className='col-sm-10 col-xs-12 py-5'>
                <div className='d-flex col-9'>
                  <p className='fs-22 fw-400'>
                  Ennocure wound therapy provides a personalized kit for the complex wound-healing process. The kit includes several objects that need an easy-to-use explanation guide using, of course, animation.
We also created the UI/UX design of the first stage of Ennocure's app prototype.
The challenge of this work was to find a way of explaining a product still in its early stages while providing the first-ever design of the company app.                  </p>
                </div>
 
                  <div className='py-3'></div>

                    <div className='col-12'>
                      <p className='fs-18 fw-400'>This work includes</p>
                        <div className='row'>
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Capabilities</h4>
                              <ul className='p-0'>
                                <li className='py-1'>UX/UI Review</li>
                                <li className='py-1'>Design System</li>
                                <li className='py-1'>Web Animation</li>
                                <li className='py-1'>Product Design</li>
                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Team</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Product Manager</li>
                                <li className='py-1'>DX Designer</li>
                                <li className='py-1'>Animator</li>
                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Cooperation</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Project</li>
  
                              </ul>
                          </div>

                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Industry</h4>
                                <li className='py-1'>Healthcare</li>
                                <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                                <li className='py-1'>2 Months, ongoing</li>

                          </div>

                        </div>

                        <div className='py-3'></div>
    
                        {/* <div className='col d-flex'>
                            <h4 className='fs-22 fw-600'>Reviews</h4>
                        </div> */}
                        <div className='my-3 '></div>

                      <div className='d-flex'>
                          <h4 className='fs-22 fw-700 dark-text'><a href='https://navina.ai' target='blank'>Visit Website &nbsp;↗</a> </h4>
                        </div>

                        </div>



                </div>

          </div>


{/* 
          <div className='row m-0 px-5'>
            <div className='col-12'>
              <video
                  className='video'
                  src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/navina_mockups0001.mp4"
                  autoPlay
                  muted
                  loop
                  style={{ maxWidth: '100%' }}
                />
            </div>
          </div> */}




          <div className='row m-0 px-5'>
            <div className='col m-2'>
                <img
                  src={ennocure_pad_demo_image}
                  width='100%'
                />
            </div>
            <div className='col m-2'>
                <img
                  src={ennocure_pad_on_arm}
                  width='100%'
                />
            </div>
          </div>
 



{/* 
          <div className='row px-5 m-0'>
          <div className='position-relative'>
                    <div className="slider">
                      <button className="slider-button prev" onClick={prevSlide}>
                          <img src={arrowIcn} className='rotate-180' width={'35px'} alt="Previous" />
                        </button>
                        <div className="slider-video">
                            {videos.map((video, index) => (
                                <video
                                    key={index}
                                    ref={(el) => (videoRefs.current[index] = el)}
                                    src={video}
                                    className={index === currentIndex ? 'active' : 'inactive'}
                                    muted
                                    autoPlay
                                    loop
                                />
                            ))}
                        </div>

                        <button className="slider-button next" onClick={nextSlide}>
                          <img src={arrowIcn} width={'35px'}  alt="Next" /> 
                        </button>


                </div>
              </div>
            </div> */}


          <div className='row px-sm-5 py-5 m-0'>
            <div className='col-12 px-5 py-5'>
              <h4 className='fw-600 fs-22 pb-3 opacity-50'>Results</h4>
                <h4 className='fs-42 fw-600'>Increase funding for the next phase of the product and close a contract to deliver the Ennocure product to a large organization.</h4>
                  {/* <div className='row border-bottom-dark-1px'>
                      <div className='col-sm-11 col-xs-12 container text-center capitalize'>
                          <div className='row px-5 py-5'>
                            <div className='col-md-4 col-sm-12 p-4'>
                                <h4 className=' fw-700'>60%</h4>
                                  <p className='fs-24 fw-400 '>more engagments</p>
                              </div>
                              <div className='col-md-4 col-sm-12 p-4'>
                                <h4 className=' fw-700'>50%</h4>
                                  <p className='fs-24 fw-400 '>increase in website time view</p>
                              </div>
                              <div className='col-md-4 col-sm-12 p-4'>
                                <h4 className=' fw-700'>55%</h4>
                                  <p className='fs-24 fw-400 '>growth in leads</p>
                              </div>

                          </div>
                        </div>
                      </div> */}
                  </div>
              </div>



          {/* <div className='row px-sm-5 pb-5 m-0'>
              <div className='col-12 px-5 pb-5'>
                  <p className='fs-22 fw-200'>
                      The team went above and beyond by making themselves readily available to address our needs and concerns, fostering a seamless and collaborative working relationship. We are genuinely appreciative of their dedication, and their exceptional project management significantly contributed to the overall success of our project. Their collaborative spirit, responsiveness, and commitment to excellence truly stood out, making them an exceptional partner in achieving our goals.
                  </p>

                  <div className='d-flex pt-4'>
                    <img
                    src={reviewerImg}
                    width='60px'
                    height='60px'
                    />
                    <p className='fs-18 fw-300 ms-3'>Niva Ziv<br></br>
                      <span className='fw-500 fs-16'>Director of Demand and Growth Marketing</span></p>
                  </div>
              </div>
          </div> */}



          <div className='row px-sm-5 m-0'>
              <div className='col d-flex'>
                  <p onClick={redirectToVayyar} className='fw-600 pointer'>Previous</p>
              </div>
              <div className='col d-flex text-right justify-content-end '>
                  <p onClick={redirectToArrows} className='fw-600 pointer'>Next</p>
              </div>
          </div>  


            <div className='row pb-5 px-sm-5 m-0'>
                <Footer/>
            </div>
        </div>


        
    </div>

  );
};


export default EnnocurePage;


