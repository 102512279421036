import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

// Pages

import ServicesPage from "./pages/Services";
import ArchPage from "./pages/Arch";
import ProposalPage from "./pages/Proposal";
import AboutPage from "./pages/About";
import IntroPage from "./pages/IntroPage";


// Poerfolio

import OtterizePage from "./pages/portfolio/Otterize";
import NavinaPage from "./pages/portfolio/Navina";
import AstrixPage from "./pages/portfolio/Astrix";
import ArrowsPage from "./pages/portfolio/Arrows";
import SparkPage from "./pages/portfolio/Spark";
import KomodorPage from "./pages/portfolio/Komodor";
import GeoxPage from "./pages/portfolio/Geox";
import VayyarPage from "./pages/portfolio/Vayyar";
import DhcPage from "./pages/portfolio/Dhc";
import ClariterPage from "./pages/portfolio/Clariter";
import EnnocurePage from "./pages/portfolio/Ennocure";
import PartnersPage from "./pages/Partners";
import Artworks from "./pages/Works";
import PlaygroundPage from "./pages/Playground";
import QuickProposalPage from "./pages/QuickProposal";
import HomePage from "./pages/Home";
import ReviewEnnocure from "./pages/ReviewEnnocure";
import AvatarsPage from "./pages/Avatars";
import VideoB2BPage from "./pages/VideoB2B";
import DevelopmentPage from "./pages/DevelopmentPage";
import WebAnimationsPage from "./pages/WebAnimations";
import GeoxITCPage from "./pages/portfolio/GeoxItc";
import Footer from "./footer/Footer";


function App() {
  return (
    <div className=''>
    <BrowserRouter>
      <Routes>
        <Route index element={<HomePage/>} />
        <Route path="/works" element={<Artworks/>} />
        <Route path="/development" element={<DevelopmentPage/>} />
        <Route path="/contact" element={<ProposalPage />} />
        <Route path="/fast-proposal" element={<QuickProposalPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/arch" element={<ArchPage />} />
        <Route path="/intro" element={<IntroPage />} />
        <Route path="/partners" element={<PartnersPage />} />
        <Route path="/avatars" element={<AvatarsPage />} />
        <Route path="/video-b2b" element={<VideoB2BPage />} />
        <Route path="/web-animation" element={<WebAnimationsPage />} />
        <Route path="/works/otterize" element={<OtterizePage />} />
        <Route path="/works/astrix" element={<AstrixPage />} />
        <Route path="/works/arrows" element={<ArrowsPage />} />
        <Route path="/works/navina" element={<NavinaPage />} />
        <Route path="/works/komodor" element={<KomodorPage />} />
        <Route path="/works/spark" element={<SparkPage />} />
        <Route path="/works/geox" element={<GeoxPage />} />
        <Route path="/works/geoxITC" element={<GeoxITCPage />} />
        <Route path="/works/vayyar" element={<VayyarPage />} />
        <Route path="/works/dhc" element={<DhcPage />} />
        <Route path="/works/clariter" element={<ClariterPage />} />
        <Route path="/works/ennocure" element={<EnnocurePage />} />
      </Routes>
    </BrowserRouter>

    <Footer/>
  </div>
  );
}

export default App;
