import React from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import VideoLoader from '../functions/VideoURLLoader';



const ProposalPage = () => {

  const videoList = [
    { folderName: 'fezzik', fileName: 'fezzik_emojies_01.mp4' },

  ];

  
  return (
    <div className='row mx-auto'>
      <Header/>
          <div className='row px-sm-5 justify-content-center align-items-center'>
            <div className='col-9'>
              <h1 className='fw-400 text-dark pb-5'>
                It's Time To Schedule Our First Date
                  </h1>

                      <div className='row'>
                          <div className='col-md col-sm col-xs-12 mt-3'>
                              <a className='fs-32 text-dark fw-500' href="mailto:hello@fezzik.art">hello@fezzik.art&nbsp;↗</a>
                                <br></br>
                                    <a className='fs-32 text-dark fw-500' href="https://api.whatsapp.com/send?phone=972528450488">Whatsapp&nbsp;↗</a>
                                      </div>

                            <div className='col-md col-sm col-xs-12 mt-3 '>
                              <p className='fs-22 m-0 p-0 fw-400'>Socials</p>
                                <a className='fs-18 text-dark fw-200 border-bottom-dark-1px py-1 me-3' href="https://www.instagram.com/fezzikart/" target='blank'>Instagram</a>
                                <a className='fs-18 text-dark fw-200 border-bottom-dark-1px py-1 me-3' href="https://www.linkedin.com/company/fezzik-art/?viewAsMember=true" target='blank'>Linkedin</a>
                                <a className='fs-18 text-dark fw-200 border-bottom-dark-1px py-1 me-3' href="/" target='blank'>Vimeo</a>
                                <a className='fs-18 text-dark fw-200 border-bottom-dark-1px py-1' href="https://www.youtube.com/@fezzik_art" target='blank'>Youtube</a>
                            </div>
                          </div>
                        </div>

               <div className='col d-flex justify-content-end'>
                <div className='my-3'>
                          <VideoLoader video={videoList[0]} videoControl="" videoAutoplay="autoPlay" videoMuted="muted" videoLoop="loop" videoWidth="100%" />
                      </div>
                </div>
                </div>

          </div>

  );
};

export default ProposalPage;
