import React from 'react';
import Header from '../header/Header';
import paz from '../assets/imgs/fezzik/paz.png'



const AboutPage = () => {
  return (
    <div className='light-background row mx-auto'>
        <Header/>

        <div className='row justify-content-center align-items-top mt-5 mb-5'>
            <div className='col-sm-8 col-xs-12 px-2 mb-4 pb-4'>


                <p className='fs-32 fw-300'>
                    Fezzik is a global creative company that has been partnering with organizations since 2014 to create a positive impact on the world. Together, we craft compelling storytelling, visuals, and motion productions that inspire meaningful action. Our mission is to uncover creative opportunities in every challenge—not just to ideate, but to bring ideas to life—and ultimately introduce extraordinary things into the world.
                </p>

                <p className='fs-32 fw-300'>
                    Fezzik is where creativity meets purpose, making and inspiring every step of the way.
                </p>


                <div className='col-md-12 col-xs-12 mt-5 mt-5 pt-5'>
                    <div className='row'>
                        <div className='col-3'>
                            <h3 className='fw-350000'>
                                Our Impact
                            </h3>
                        </div>
                        <div className='col'>
                            <p className='fs-22 fw-300'>
                                Introducing our inaugural 2023 Impact Report, representing a milestone in BUCK’s journey to measure and report on its impact globally. The report outlines how we continue to do better for our people and our planet.
                                </p>
                        </div>

                </div>
            </div>



                <div className='col-md-12 col-xs-12 mt-5 mt-5 pt-5'>
                    <div className='row'>
                        <div className='col-4'>
                        <img className='img-fluid mb-3' width={'200px'} src={paz} alt='paz roth'/>
                            <h4 className='fw-200 p-0 m-0'>
                                Paz Roth
                            </h4>
                            <p className='fs-20 fw-400 p-0 m-0'>
                            Founder
                            </p>

                    </div>

                    <div className='col'>
                            <h2 className='fw-300'>
                                Behind every project stand people. Together, we turn projects into trusting partnerships through our shared values & commitment.
                            </h2>
                        </div>


                </div>
            </div>




       
  



            </div>
        </div>
    </div>
     
  );
};

export default AboutPage;
