import React from 'react';
import Header from '../header/Header';

import ARCH_img from '../assets/imgs/fezzik/arch-demo-services.png';


const ServicesPage = () => {
  return (  
    <div className='light-background row mx-auto h-100'>
        <Header/>


    <div class="row mx-auto align-items-top mt-5">
    <div className='col-5 pe-5'></div>


                <div class="col pe-sm-5">
                    <h2 className='mb-5'>
                        Crafting Value, Driving Results: Stunning Visual Experiences in Design and Animation that Inspire Action.
                                </h2>

                        <div class="mb-5">
                            <h4>
                            Engaging Animation Experiences 
                            </h4>
                            <p class="fw-300 fs-22">
                            Bring your ideas to life with our high-quality animations. Whether it’s explainer videos, promotional content, or dynamic motion graphics, we craft visuals that captivate audiences and inspire action.
                                                        </p>
                        </div>

                        <div class="mb-5">
                            <h4>
                                Impactful Video Production
                            </h4>
                            <p class="fw-300 fs-22">
                            We produce creative, high-quality videos tailored to showcase your products and services. Our videos effectively communicate your message, drive calls to action, and amplify engagement with your target audience.

                            </p>
                        </div>


                        <div class="mb-5">
                            <h4>
                            Captivating Visual Design
                            </h4>
                            <p class="fw-300 fs-20">
                            Our design services transform ideas into visually stunning realities. From branding to product visuals, we create designs that not only elevate your brand’s appearance but also leave a lasting impression on your audience.
                                                        </p>
                        </div>



                        <div class="mb-5">
                            <h4>
                            Innovative Web Development
                            </h4>
                            <p class="fw-300 fs-20">
                            We design and develop intuitive, user-friendly websites and platforms. Our solutions are crafted to not only look great but also provide seamless functionality, helping your brand stand out in the digital space.
                                </p>
                        </div>



                        <div class="mb-5">
                            <h4>
                            Results-Driven Creativity
                            </h4>
                            <p class="fw-300 fs-20">
                            Our team blends creativity and strategy to deliver solutions that work. Whether designing, animating, or developing, we focus on enhancing your brand’s appeal, driving engagement, and achieving measurable results.
                            </p>
                        </div>




                    </div>
        


    
    </div>




<div className='row h-100 mt-5 mx-auto px-4 justify-content-center align-items-center'>
    <div className='col-10'>
    <div class="row px-4 ">
        <div class="col-4 col-sm-3 p-2">
            <div class="box d-flex justify-content-center align-items-center">
                <h6 class="text-center fw-normal">
                Website and App Animations
                </h6>
            </div>
        </div>
        <div class="col-4 col-sm-3 p-2">
            <div class="box d-flex justify-content-center align-items-center">
                <h6 class="text-center fw-normal">
                Explainer Videos
                </h6>
            </div>
        </div>
        <div class="col-4 col-sm-3 p-2">
            <div class="box d-flex justify-content-center align-items-center">
                <h6 class="text-center fw-normal">
                Product Videos
                </h6>
            </div>
        </div>
        <div class="col-4 col-sm-3 p-2">
            <div class="box d-flex justify-content-center align-items-center">
                <h6 class="text-center fw-normal">
                Video for Shows
                </h6>
            </div>
        </div>
        <div class="col-4 col-sm-3 p-2">
            <div class="box d-flex justify-content-center align-items-center">
                <h6 class="text-center fw-normal">
                Architectural Videos
                </h6>
            </div>
        </div>
        <div class="col-4 col-sm-3 p-2">
            <div class="box d-flex justify-content-center align-items-center">
                <h6 class="text-center fw-normal">
                Tutorial Videos
                </h6>
            </div>
        </div>
        <div class="col-4 col-sm-3 p-2">
            <div class="box d-flex justify-content-center align-items-center">
                <h6 class="text-center fw-normal">
                3D Visualizations
                </h6>
            </div>
        </div>
        <div class="col-4 col-sm-3 p-2">
            <div class="box d-flex justify-content-center align-items-center">
                <h6 class="text-center fw-normal">
                Character Design
                </h6>
            </div>
        </div>
        <div class="col-4 col-sm-3 p-2">
            <div class="box d-flex justify-content-center align-items-center">
                <h6 class="text-center fw-normal">
                AR & VR Animations
                </h6>
            </div>
        </div>
        <div class="col-4 col-sm-3 p-2">
            <div class="box d-flex justify-content-center align-items-center">
                <h6 class="text-center fw-normal">
                Gaming and Apps
                </h6>
            </div>
        </div>
        <div class="col-4 col-sm-3 p-2">
            <div class="box d-flex justify-content-center align-items-center">
                <h6 class="text-center fw-normal">
                Pre-Production Services
                </h6>
            </div>
        </div>
        <div class="col-4 col-sm-3 p-2">
            <div class="box d-flex justify-content-center align-items-center">
                <h6 class="text-center fw-normal">
                Post-Production & Editing
                </h6>
            </div>
        </div>
        <div class="col-4 col-sm-3 p-2">
            <div class="box d-flex justify-content-center align-items-center">
                <h6 class="text-center fw-normal">
                Brand Design
                </h6>
            </div>
        </div>
        <div class="col-4 col-sm-3 p-2">
            <div class="box d-flex justify-content-center align-items-center">
                <h6 class="text-center fw-normal">
                    Service Design
                </h6>
            </div>
        </div>
        <div class="col-4 col-sm-3 p-2">
            <div class="box d-flex justify-content-center align-items-center">
                <h6 class="text-center fw-normal">
                    UI Development
                </h6>
            </div>
        </div>
        <div class="col-4 col-sm-3 p-2">
            <div class="box d-flex justify-content-center align-items-center">
                <h6 class="text-center fw-normal">
                    Visual Experiences
                </h6>
            </div>
        </div>
    </div>
    </div>
    </div>











    <div className='row px-sm-4 h-60 justify-content-center align-items-center'>
               <div className='col-sm-8 col-xs-12 text-center'>
                <h2>On-Demand Creative Talent, In-House</h2>
                <p className='mt-4 fs-22 fw-300'>
                Need a skilled designer or animator to join your team temporarily? We offer flexible, part-time in-house solutions tailored to your short-term projects. Our experts integrate seamlessly into your company, bringing fresh creativity, technical expertise, and a commitment to deliver exceptional results—all while working closely with your team to ensure your vision is realized.
                </p>

                <div className="d-inline-flex rounded-5 border-dark-2px mt-3 px-3 py-1 cta-button dark-text" ><a href="/contact" className='fs-20 fw-700 capitalize dark-text'>Contact Us</a></div>

               </div>

               
               </div>



{/* 
               <div className='col-lg-12 col-md-10 col-sm-12 col-xs-12 text-center '>
                <h2 className='fw-400'>
                    We believe in the power of animation to transform brands, convey messages, and spark imagination. Our mission is to craft unforgettable animations that captivate and convert.
                </h2>
            </div> */}



            <div className='row px-4 mb-5'>
               <div className='col-md col-sm col-xs-12 mt-3'>
                <p className='fs-18 m-0 p-0 fw-400'>Reach out</p>
                  <a className='fs-32 text-dark fw-500' href="mailto:hello@fezzik.art">hello@fezzik.art&nbsp;↗</a>
                    <br></br>
                        <a className='fs-32 text-dark fw-500' href="https://api.whatsapp.com/send?phone=972528450488">Whatsapp&nbsp;↗</a>
               </div>
               </div>



               </div>
     
  );
};

export default ServicesPage;
